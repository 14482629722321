function TimePicker(elem) {
  this.options = {shouldSort: false, searchEnabled: false, itemSelectText: ''};

  this.init = function() {
    new Choices(elem, this.options);
    elem.classList.remove('disabled');
  };

  this.init();
}

function DateTimeFieldGroups() {
  this.groupSelector = '.consultation-form .date-time-field-group.hidden';
  this.expandLink = document.querySelector('.consultation-form .expand-link');

  this.showExpandLinkUnlessLast = function() {
    if (document.querySelector(this.groupSelector) && this.expandLink) {
      this.expandLink.classList.remove('hidden');
    }
  };

  this.hideExpandLinkIfLast = function(link) {
    if (!document.querySelector(this.groupSelector)) {
      link.classList.add('hidden');
    }
  };

  this.init = function() {
    if (!document.querySelector('.consultation-form')) {
      return;
    }

    var groups = this;
    var closeLinks =
      Array.prototype.slice.call(document.querySelectorAll('.consultation-form .close-link'));


    if (closeLinks && closeLinks.length) {
      closeLinks.forEach(function(link) {
        link.addEventListener('click', function() {
          var fieldGroup = this.parentNode;
          if (fieldGroup) {
            fieldGroup.classList.add('hidden');
            var inputs = fieldGroup.querySelectorAll('input, select');
            if (inputs && inputs.length) {
              inputs.forEach(function(input) {
                input.disabled = true;
              });
            }
          }
          groups.showExpandLinkUnlessLast();
        });
      });
    }

    if (this.expandLink) {
      this.expandLink.addEventListener('click', function() {
        nextGroup = document.querySelector(groups.groupSelector);
        if (nextGroup) {
          nextGroup.classList.remove('hidden');
          var disabledInputs = nextGroup.querySelectorAll('[disabled]');
          if (disabledInputs && disabledInputs.length) {
            disabledInputs.forEach(function(input) {
              input.disabled = false;
            });
          }
          var timeInput = nextGroup.querySelector('select.disabled');
          if (timeInput) {
            new TimePicker(timeInput);
          }
        }
        groups.hideExpandLinkIfLast(this);
      });
    }
  };

  this.init();
}

$(function() {
  initValidation('is-form');
  initValidation('consultation-form');

  // date picker
  $('.consultation-form-date').flatpickr({
    dateFormat: 'm/d/Y',
    nextArrow: '&#9654;',
    prevArrow: '&#9664;',
    disableMobile: true
  });

  // selects
  var firstTimePicker = document.getElementById('contact_time_0');
  if (firstTimePicker) {
    new TimePicker(firstTimePicker);
  }

  var locationPicker = document.querySelector('select#contact-location');
  if (locationPicker) {
    new TimePicker(locationPicker);
  }

  new DateTimeFieldGroups();
});

// document.addEventListener('submit', function(e) {
//   if (e.target.action.match(/eiiforms/g).length) {
//     // Disable button after submit to prevent multiple submissions
//     e.preventDefault();
//     var form = e.target;
//     var submitBtn = form.querySelector('[type="submit"]');
//     submitBtn.disabled = true;
//     form.submit();
//   }
// });

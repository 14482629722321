var lucidUnlocked = function () {
  return $('html').hasClass('xlucid_unlocked');
};

var overlayOpen = function () {
  return $('.overlay').hasClass('active');
};

function VideoOverlay(id) {
  this.id = id;
  this.video = null;

  this.init = function() {
    var elem = document.getElementById(this.id);
    if (!elem) return;

    var videoType = 'video/mp4';
    var features = ['playpause', 'current', 'progress', 'duration', 'tracks',
                    'volume', 'fullscreen'];

    this.video = new MediaElementPlayer(elem, {
      type: videoType,
      features: features,
      AndroidUseNativeControls: true,
      iPhoneUseNativeControls: true,
      iPadUseNativeControls: true,
      success: function (mediaElement) {
        mediaElement.addEventListener('ended', function(e) {
          var cta = document.querySelector('.after-play-wrapper');
          if (cta) cta.classList.add('active');
        });
        mediaElement.addEventListener('webkitendfullscreen', function() {
          window.history.back();
        });
      }
    });

    return this.video;
  }

  this.play = function() {
    if (!this.video) return;

    return this.video.play();
  }

  this.pause = function() {
    if (!this.video) return;

    return this.video.pause();
  }

  this.init();
}

$(function() {
  var $videoOverlay = new VideoOverlay('video-overlay-player');

  var closePageOverlay = function () {
    $('.overlay').removeClass('active');
    $.scrollLock();
    if ($videoOverlay) {
      $videoOverlay.pause();
    }
  };

  var addPushState = function () {
    var scrollTop = $('body').scrollTop();

    history.replaceState({scrollTop: scrollTop}, null, null);
    history.pushState({overlay: 'open'}, null, null);
  };

  var initPageOverlayCloser = function () {
    window.onpopstate = function (event) {
      if (!overlayOpen()) {
        return;
      }

      var scrollTop = event.state.scrollTop;

      closePageOverlay();
      if (scrollTop) {
        $('body').scrollTop(scrollTop);
      }
    };

    $(document).on('keyup', function (e) {
      if (!overlayOpen() || e.keyCode !== 27) return;

      closePageOverlay();
    });
  };

  var activateOverlay = function ($elem) {
    $elem.toggleClass('active');
    addPushState();
    $.scrollLock();
    initPageOverlayCloser();
  };

  var loadDelayedImages = function ($elem) {
    $elem.find('img.delayed').each(function () {
      var $image = $(this);
      var src    = $image.data('src');

      $image.attr('src', src);
      $image.removeClass('delayed');
    });
  };

  $('.overlay-close').on('click', function () {
    window.history.back();
  });

  $('.overlay .close-area').on('click', function () {
    window.history.back();
  });

  $('.overlay-contact-open').on('click', function () {
    activateOverlay($('#overlay-contact'));
  });

  $('.overlay-menu-open').on('click', function () {
    activateOverlay($('#overlay-menu'));
  });

  $('.overlay-phone-open').on('click', function () {
    activateOverlay($('#overlay-phone'));
  });

  $('.overlay-social-open').on('click', function () {
    activateOverlay($('#overlay-social'));
  });

  $('.overlay-blog-archive-open').on('click', function () {
    activateOverlay($('#overlay-blog-archive'));
  });

  $('.overlay-blog-tags-open').on('click', function () {
    activateOverlay($('#overlay-blog-tags'));
  });

  $('.overlay-ppc-open').on('click', function () {
    activateOverlay($('#overlay-ppc'));
  });

  $('.overlay-location-open').on('click', function () {
    loadDelayedImages($('#overlay-locations'));
    activateOverlay($('#overlay-locations'));
  });

  if ($videoOverlay) {
    var cta = document.querySelector('.after-play-wrapper');

    $('.overlay-video-open').on('click', function () {
      var $overlay = $('#overlay-video');
      activateOverlay($overlay);
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });

    $('.video-overlay-replay-link').on('click', function () {
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });
  }

});

jQuery(document).ready(function() {

  $('.tabs, .all-pages').foundation();

  // $("a.button").wrapInner("<span></span>");

  $(".field.message").removeClass("message");

	$( ".team-leader" ).wrapAll( "<div class='row align-center team-leaders'></div>" );

	$( ".team-member" ).wrapAll( "<div class='row align-center team-members'></div>" );

  var imgs = document.getElementsByTagName('img');
  for (var i=0; i<imgs.length; i++) {
    if (imgs[i].getAttribute('data-defer-src')) {
      imgs[i].setAttribute('src', imgs[i].getAttribute('data-defer-src'));
    }
  }

  var tags = document.querySelectorAll('[data-defer-style]');
  for (var i=0; i<tags.length; i++) {
    tags[i].setAttribute('style', tags[i].getAttribute('data-defer-style'));
  }
});

document.addEventListener('DOMContentLoaded', function() {
  LoopingVideo('video.looping-video');
});

jQuery(window).scroll(function() {
    var scroll = jQuery(window).scrollTop();
    if (scroll >= 100) {
        jQuery(".sticky").addClass("shrink");
    } else {
        jQuery(".sticky").removeClass("shrink");
    }
});
